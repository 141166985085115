/* eslint-disable */

import moment from 'moment'
import { useRef, useEffect } from 'react'
import Calendar from '../modules/toastui-react-calendar'
import '../modules/toastui-calendar.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarPlus,
  faChevronLeft,
  faChevronRight,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import Modal from './Modal'
import { useNavigate } from 'react-router-dom'
import { Switch } from '@headlessui/react'

function TuiCalendar({ events, loadEvents, calendarType, userId = null }) {
  const WEEK_DAY_START = 1
  const DAY_START_HOUR = 7
  const DAY_END_HOUR = 23

  const [currentRange, setCurrentRange] = useState({
    from: moment().startOf('isoWeek'),
    to: moment().endOf('isoWeek'),
  })
  const navigate = useNavigate()
  const [availableServices, setAvailableServices] = useState([])

  const [addAvailabilityFlag, setAddAvailabilityFlag] = useState(false)
  const [editAvailabilityFlag, setEditAvailabilityFlag] = useState(false)
  const [editBookingFlag, setEditBookingFlag] = useState(false)
  const [viewBookingFlag, setViewBookingFlag] = useState(false)
  const [deleteAvailability, setDeleteAvailability] = useState({})
  const [availabilityData, setAvailabilityData] = useState({ services: [] })
  const [availabilityDate, setAvailabilityDate] = useState('')
  const [viewBookingDate, setViewBookingDate] = useState('')
  const [viewBookingSlot, setViewBookingSlot] = useState('')
  const [viewBookingClient, setViewBookingClient] = useState('')
  const [viewBookingVideoSession, setViewBookingVideoSession] = useState(false)
  const [startTimeValid, setStartTimeValid] = useState(true)
  const [endTimeValid, setEndTimeValid] = useState(true)
  const [cancelSession, setCancelSession] = useState({ reason_id: '0' })
  const [cancelReasons, setCancelReasons] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [bookingLoaded, setBookingLoaded] = useState(false)
  const [availabilityLoaded, setAvailabilityLoaded] = useState(false)

  const { setShowLoader, createInfo, admin } = useAuth()
  const axios = useAxiosPrivate()

  const [loaded, setLoaded] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const bookingCalendarRef = useRef()
  const availabilityCalendarRef = useRef()

  useEffect(() => {
    bookingCalendarRef.current.calendarInstance.clear()
    availabilityCalendarRef.current.calendarInstance.clear()
    const data = []
    events.forEach((event) => {
      if (calendarType === 'bookings' && userId) {
        let colour
        if (event.cancelled === 1) {
          colour = '#fca5a5'
          console.log(event)
        } else if (event.type_id === 1) {
          colour = '#94a3b8'
        } else {
          colour = event.service.calendar_bg_color
        }
        data.push({
          id: 1,
          body: event.service.title,
          start: event.start_time,
          end: event.end_time,
          attendees: [event.client.name],
          color: '#fff',
          backgroundColor: colour,
          borderColor: event.service.calendar_border_color,
          raw: event,
        })
      }

      if (calendarType === 'bookings' && !userId) {
        data.push({
          id: 1,
          body: event.service.title,
          start: event.start_time,
          end: event.end_time,
          attendees: [event.user.name],
          color: '#fff',
          backgroundColor: event.service.calendar_bg_color,
          borderColor: event.service.calendar_border_color,
          raw: event,
        })
      }
      if (calendarType === 'availability') {
        data.push({
          id: event.id,
          start: event.start_time,
          end: event.end_time,
          attendees: [],
          color: '#fff',
          backgroundColor: '#03a9f4',
          borderColor: '#03a9f4',
          raw: event,
        })
      }
    })

    if (calendarType === 'bookings') {
      bookingCalendarRef.current.calendarInstance.createEvents(data)
    }
    if (calendarType === 'availability') {
      availabilityCalendarRef.current.calendarInstance.createEvents(data)
    }
  }, [events])

  const getUserServices = async () => {
    try {
      const response = await axios.get(`/users/${userId}/services`)
      setAvailableServices(response.data.result)
    } catch (error) {}
  }

  useEffect(() => {
    if (userId) {
      getUserServices()
    }
  }, [])

  useEffect(() => {
    if (calendarType === 'bookings' && !bookingLoaded) {
      setBookingLoaded(true)
      bookingCalendarRef.current.calendarInstance.on(
        'selectDateTime',
        selectTime
      )
      if (userId) {
        bookingCalendarRef.current.calendarInstance.on('clickEvent', clickEvent)
      } else {
        bookingCalendarRef.current.calendarInstance.on(
          'clickEvent',
          nonUserClickEvent
        )
      }
    }
    if (calendarType === 'availability' && !availabilityLoaded) {
      setAvailabilityLoaded(true)
      availabilityCalendarRef.current.calendarInstance.on(
        'selectDateTime',
        selectTime
      )
      if (userId) {
        availabilityCalendarRef.current.calendarInstance.on(
          'clickEvent',
          clickEvent
        )
      }
    }

    loadEvents(
      moment().startOf('isoWeek').format('YYYY-MM-DD'),
      moment().endOf('isoWeek').format('YYYY-MM-DD')
    )
    setLoaded(true)
    setCurrentRange({
      from: moment().startOf('isoWeek'),
      to: moment().endOf('isoWeek'),
    })
  }, [calendarType])

  const getTimeTemplate = (schedule, isAllDay) => {
    let html = []
    let start = moment(new Date(schedule.start))
    let end = moment(new Date(schedule.end))
    if (!isAllDay) {
      html.push('<span style="margin-top: 5px; margin-left: 2px; display: flex">')
        if(schedule.raw?.video_session === 1) {
          html.push('<svg xmlns="http://www.w3.org/2000/svg" style="width: 15px; fill: white; margin-right: 4px;" viewBox="0 0 576 512"><path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z" /></svg>')
        }
        html.push('<svg style="width: 15px; fill: white; margin-right: 4px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg><strong style="font-size: 12px">' +
          start.format('HH:mm') +
          ' - ' +
          end.format('HH:mm') +
          '</strong></span>'
      )
    }
    if (schedule.raw?.services) {
      html.push(
        '<div style="display: flex; flex-direction: column; margin-top: 5px">'
      )
      schedule.raw.services.forEach((service) => {
        html.push(
          `<span class="badge badge-pill mb-1 mr-1" style="background: ${service.calendar_bg_color};">${service.title}</span>`
        )
      })
      html.push('</div>')
    }

    if (schedule.isPending) {
      html.push('<i title="Pending" class="far fa-hourglass-half"></i>')
      html.push(' ' + schedule.title + ' ')
      html.push('<span style="font-weight: lighter;">Pending</span>')
    } else if (schedule.raw && schedule.raw.isCancelled) {
      html.push('<i title="Cancelled" class="far fa-do-not-enter"></i>')
      html.push(' ' + schedule.title + ' ')
      html.push('<span style="font-weight: lighter;">Cancelled</span>')
    } else {
      html.push(' ' + schedule.title)
    }

    return html.join('')
  }

  const getPopupDetailAttendees = (schedule) => {
    return schedule.attendees
  }

  const getPopupDetailBody = (schedule) => {
    const html = []

    if (schedule.isPending) {
      html.push('(Pending)')
    }
    if (schedule.raw && schedule.raw.isCancelled) {
      html.push('(Cancelled)')
    }
    html.push(schedule.body)

    return html.join('\n')
  }

  const goToToday = () => {
    setLoaded(false)
    setCurrentRange({
      from: moment().startOf('isoWeek'),
      to: moment().endOf('isoWeek'),
    })
    calendarType === 'bookings'
      ? bookingCalendarRef.current.calendarInstance.today()
      : availabilityCalendarRef.current.calendarInstance.today()
  }

  const nextWeek = () => {
    setLoaded(false)
    setCurrentRange({
      from: moment(currentRange.from).add(7, 'days'),
      to: moment(currentRange.to).add(7, 'days'),
    })
    calendarType === 'bookings'
      ? bookingCalendarRef.current.calendarInstance.next()
      : availabilityCalendarRef.current.calendarInstance.next()
  }

  const prevWeek = () => {
    setLoaded(false)
    setCurrentRange({
      from: moment(currentRange.from).subtract(7, 'days'),
      to: moment(currentRange.to).subtract(7, 'days'),
    })
    calendarType === 'bookings'
      ? bookingCalendarRef.current.calendarInstance.prev()
      : availabilityCalendarRef.current.calendarInstance.prev()
  }

  const loadCalendar = () => {
    setLoaded(true)
    loadEvents(
      currentRange.from.format('YYYY-MM-DD'),
      currentRange.to.format('YYYY-MM-DD')
    )
  }

  const visitClient = (id) => {
    navigate(`/clients/${id}`)
  }

  const addBooking = (day = null, time = null) => {
    console.log(day)
    console.log(time)
  }

  const addAvailability = (
    date = moment().format('YYYY-MM-DD'),
    time = { start: '11:00', end: '12:00' }
  ) => {
    let allServices = []
    availableServices.forEach((service) => {
      if (service.status !== 'Disabled') allServices.push(service.id)
    })
    setAvailabilityDate(date)
    setAvailabilityData({
      start: time.start,
      end: time.end,
      services: allServices,
      repeat_until: moment(date).add(6, 'months').format('YYYY-MM-DD'),
    })
    setAddAvailabilityFlag(true)
    setModalTitle(`Add Availability Slot`)
    setShowModal(true)
  }

  const deleteAvailabilityClicked = (availability) => {
    setShowModal(false)
    setEditAvailabilityFlag(false)
    setDeleteAvailability(availability)
    setModalTitle(`Delete Availability`)
    setShowModal(true)
  }

  const cancelBookingClicked = (booking) => {
    setShowModal(false)
    setEditBookingFlag(false)
    setViewBookingFlag(false)
    setCancelSession(booking)
    setModalTitle(`Cancel Session`)
    setShowModal(true)
  }

  const editAvailability = (event) => {
    let allServices = []
    event = { ...event.raw }
    event.services.forEach((service) => {
      if (service.status !== 'Disabled') allServices.push(service.id)
    })
    setAvailabilityDate(moment(event.start_time).format('YYYY-MM-DD'))
    event.start = moment(event.start_time).format('HH:mm')
    event.end = moment(event.end_time).format('HH:mm')
    event.services = allServices
    setAvailabilityData(event)
    setEditAvailabilityFlag(true)
    setModalTitle(`Edit Availability`)
    setShowModal(true)
  }

  const editBooking = (event) => {
    let allBookings = []
    event = { ...event.raw }
    setViewBookingDate(moment(event.start_time).format('YYYY-MM-DD'))
    event.start = moment(event.start_time).format('HH:mm')
    event.end = moment(event.end_time).format('HH:mm')
    event.services = allBookings
    setViewBookingSlot(event)
    setViewBookingClient(event.client)
    if(event.video_session === 1) {
      setViewBookingVideoSession(true)
    } else {
      setViewBookingVideoSession(false)
    }
    setEditBookingFlag(true)
    setModalTitle(`Edit Session`)
    setShowModal(true)
  }

  const viewBooking = (event) => {
    let allBookings = []
    event = { ...event.raw }
    setViewBookingDate(moment(event.start_time).format('YYYY-MM-DD'))
    event.start = moment(event.start_time).format('HH:mm')
    event.end = moment(event.end_time).format('HH:mm')
    event.services = allBookings
    setViewBookingSlot(event)
    setViewBookingClient(event.client)
    if(event.video_session === 1) {
      setViewBookingVideoSession(true)
    } else {
      setViewBookingVideoSession(false)
    }
    setViewBookingFlag(true)
    setModalTitle(`View Session`)
    setShowModal(true)
  }

  const selectTime = (e) => {
    const day = moment(e.start).format('YYYY-MM-DD')
    const start = moment(e.start).format('HH:mm')
    const end = moment(e.end).format('HH:mm')
    if (calendarType === 'bookings') {
      addBooking(day, { start, end })
    }
    if (calendarType === 'availability') {
      addAvailability(day, { start, end })
    }
  }

  const clickEvent = (e) => {
    if (calendarType === 'availability') {
      editAvailability(e.event)
    }

    if (calendarType === 'bookings') {
      editBooking(e.event)
    }
  }

  const nonUserClickEvent = (e) => {
    if (calendarType === 'bookings') {
      viewBooking(e.event)
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
    loadEvents(
      currentRange.from.format('YYYY-MM-DD'),
      currentRange.to.format('YYYY-MM-DD'),
      !isChecked
    )
  }

  const ModalBody = () => {
    if (addAvailabilityFlag) {
      return <AddAvailabilityElement />
    }

    if (editAvailabilityFlag) {
      return <EditAvailabilityElement />
    }

    if (deleteAvailability.id) {
      return <DeleteAvailabilityElement />
    }

    if (editBookingFlag) {
      return <EditSessionElement />
    }

    if (viewBookingFlag) {
      return <ViewSessionElement />
    }

    if (cancelSession.id) {
      return <CancelSessionElement />
    }
  }

  const intervals = (start, end) => {
    start = moment(start, 'HH:mm')
    end = moment(end, ' HH:mm')

    start.minutes(Math.ceil(start.minutes() / 15) * 15)

    let result = []

    let current = moment(start)

    while (current <= end) {
      result.push(current.format('HH:mm'))
      current.add(15, 'minutes')
    }

    return result
  }

  const checkTime = (start, end) => {
    setStartTimeValid(true)
    setEndTimeValid(true)
    if (
      moment(end, 'HH:mm').format('HH:mm') <=
      moment(start, 'HH:mm').format('HH:mm')
    ) {
      return false
    }
    return true
  }

  const AddAvailabilityElement = () => {
    const addAvailability = async (e) => {
      e.preventDefault()

      if (!startTimeValid || !endTimeValid) {
        createInfo('error', `The start time must be before the end time`)
        return
      }
      availabilityData.date = availabilityDate
      availabilityData.repeat = availabilityData.repeat || 0

      setShowLoader(true)
      try {
        const response = await axios.post(
          `/users/${userId}/availability/add`,
          availabilityData
        )
        if (response.data.error === false) {
          const availability = response.data.result
          createInfo('success', `Availability Added`)
          availabilityCalendarRef.current.calendarInstance.createEvents([
            {
              id: availability.id,
              start: availability.start_time,
              end: availability.end_time,
              attendees: [],
              color: '#fff',
              backgroundColor: '#03a9f4',
              borderColor: '#03a9f4',
              raw: availability,
            },
          ])
          availabilityCalendarRef.current.calendarInstance.clearGridSelections()
          resetModal()
        }
      } catch (error) {}
      setShowLoader(false)
    }

    const updateAvailabilityServices = (service) => {
      let index = availabilityData.services.indexOf(service.id)
      if (index === -1) {
        availabilityData.services.push(service.id)
      } else {
        availabilityData.services.splice(index, 1)
      }
    }

    return (
      <form onSubmit={addAvailability}>
        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="date"
              name="date"
              id="date"
              defaultValue={availabilityDate}
              onChange={(e) => {
                setAvailabilityDate(e.target.value)
                availabilityData.repeat_until = moment(e.target.value)
                  .add(6, 'months')
                  .format('YYYY-MM-DD')
              }}
              min={moment().format('YYYY-MM-DD')}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className={`mb-3 ${!startTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="start"
            className="block font-medium text-gray-600 text-sm"
          >
            Start
          </label>
          <select
            defaultValue={availabilityData.start}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(e.target.value, availabilityData.end)
              setStartTimeValid(valid)
              availabilityData.start = e.target.value
            }}
          >
            <option disabled>Select Start Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!startTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        <div className={`mb-3 ${!endTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="end"
            className="block font-medium text-gray-600 text-sm"
          >
            End
          </label>
          <select
            defaultValue={availabilityData.end}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(availabilityData.start, e.target.value)
              setEndTimeValid(valid)
              availabilityData.end = e.target.value
            }}
          >
            <option disabled>Select End Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!endTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        <div className="mt-3 w-full mr-2">
          <h3 className="block font-medium text-gray-600 text-sm">Services</h3>
          <div className="flex flex-wrap w-full">
            {availableServices?.map((service) => {
              if (service.status === 'Disabled') return null
              return (
                <div className="flex items-center mr-4" key={service.id}>
                  <div className="relative mr-2">
                    <input
                      type="checkbox"
                      id={service.id}
                      value={service.id}
                      defaultChecked={availabilityData.services.includes(
                        service.id
                      )}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                      onChange={() => updateAvailabilityServices(service)}
                    />
                  </div>
                  <label
                    htmlFor={service.id}
                    className="block font-medium text-gray-600 text-sm"
                  >
                    {service.title}
                  </label>
                </div>
              )
            })}
          </div>
        </div>

        <div className="mt-3">
          <h3 className="block font-medium text-gray-600 text-sm">Repeat</h3>
          <div className=" w-full mr-2 flex items-center">
            <div className="relative mr-2">
              <input
                type="checkbox"
                id="repeat"
                onChange={(e) => {
                  setAvailabilityData({
                    start: availabilityData.start,
                    end: availabilityData.end,
                    services: availabilityData.services,
                    repeat: e.target.checked,
                    repeat_until: availabilityData.repeat_until,
                  })
                }}
                placeholder="Repeat Availability"
                className="field"
                checked={availabilityData.repeat}
              />
            </div>
            <label
              htmlFor="repeat"
              className="block font-medium text-gray-600 text-sm"
            >
              Every {moment(availabilityDate).format('dddd')}{' '}
              <span className="text-red-400">(for a maximum of 6 months)</span>
            </label>
          </div>
        </div>

        {availabilityData.repeat && (
          <div className="mt-3">
            <label
              htmlFor="date"
              className="block font-medium text-gray-600 text-sm"
            >
              Repeat Until
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                type="date"
                name="date"
                id="date"
                max={moment(availabilityDate)
                  .add(6, 'months')
                  .format('YYYY-MM-DD')}
                defaultValue={availabilityData.repeat_until}
                onChange={(e) =>
                  (availabilityData.repeat_until = e.target.value)
                }
                min={moment().format('YYYY-MM-DD')}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
        )}

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Add Availability</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const EditAvailabilityElement = () => {
    const updateAvailability = async (e) => {
      e.preventDefault()

      if (!startTimeValid || !endTimeValid) {
        createInfo('error', `The start time must be before the end time`)
        return
      }
      availabilityData.date = availabilityDate

      setShowLoader(true)
      try {
        const response = await axios.post(
          `/availabilities/update/${availabilityData.id}`,
          availabilityData
        )
        if (response.data.error === false) {
          createInfo('success', `Availability Updated`)
          setShowLoader(true)
          loadEvents(
            currentRange.from.format('YYYY-MM-DD'),
            currentRange.to.format('YYYY-MM-DD')
          )
          setShowLoader(false)
          resetModal()
        }
      } catch (error) {}
      setShowLoader(false)
    }

    const updateAvailabilityServices = (service) => {
      let index = availabilityData.services.indexOf(service.id)
      if (index === -1) {
        availabilityData.services.push(service.id)
      } else {
        availabilityData.services.splice(index, 1)
      }
    }

    return (
      <form onSubmit={updateAvailability}>
        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="date"
              name="date"
              id="date"
              defaultValue={availabilityDate}
              onChange={(e) => {
                setAvailabilityDate(e.target.value)
                availabilityData.repeat_until = moment(e.target.value)
                  .add(6, 'months')
                  .format('YYYY-MM-DD')
              }}
              min={moment().format('YYYY-MM-DD')}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className={`mb-3 ${!startTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="start"
            className="block font-medium text-gray-600 text-sm"
          >
            Start
          </label>
          <select
            defaultValue={availabilityData.start}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(e.target.value, availabilityData.end)
              setStartTimeValid(valid)
              availabilityData.start = e.target.value
            }}
          >
            <option disabled>Select Start Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!startTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        <div className={`mb-3 ${!endTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="end"
            className="block font-medium text-gray-600 text-sm"
          >
            End
          </label>
          <select
            defaultValue={availabilityData.end}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(availabilityData.start, e.target.value)
              setEndTimeValid(valid)
              availabilityData.end = e.target.value
            }}
          >
            <option disabled>Select End Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!endTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        <div className="mt-3 w-full mr-2">
          <h3 className="block font-medium text-gray-600 text-sm">Services</h3>
          <div className="flex flex-wrap w-full">
            {availableServices?.map((service) => {
              if (service.status === 'Disabled') return null
              return (
                <div className="flex items-center mr-4" key={service.id}>
                  <div className="relative mr-2">
                    <input
                      type="checkbox"
                      id={service.id}
                      value={service.id}
                      defaultChecked={availabilityData.services.includes(
                        service.id
                      )}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                      onChange={() => updateAvailabilityServices(service)}
                    />
                  </div>
                  <label
                    htmlFor={service.id}
                    className="block font-medium text-gray-600 text-sm"
                  >
                    {service.title}
                  </label>
                </div>
              )
            })}
          </div>
        </div>

        <div className="w-full flex justify-end">
          <p
            className="text-red-500 text-sm cursor-pointer hover:text-red-700"
            onClick={() => deleteAvailabilityClicked(availabilityData)}
          >
            Delete Occurence
          </p>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const EditSessionElement = () => {
    const updateBooking = async (e) => {
      e.preventDefault()
      let video_session = 0
      if (viewBookingVideoSession) {
        video_session = 1
      } else {
        video_session = 0
      }
      const data = {
        start_time:
          moment(viewBookingDate).format('YYYY-MM-DD') +
          ' ' +
          viewBookingSlot.start +
          ':00',
        end_time:
          moment(viewBookingDate).format('YYYY-MM-DD') +
          ' ' +
          viewBookingSlot.end +
          ':00',
          video_session: video_session
      }

      if (!startTimeValid || !endTimeValid) {
        createInfo('error', `The start time must be before the end time`)
        return
      }

      let isTimeAvailable = true

      events.map((event) => {
        if (
          event.user_id === viewBookingSlot.user_id &&
          viewBookingSlot.id !== event.id
        ) {
          // The third parameter 'undefined' is the unit of time to compare eg. 'year'.
          // The fourth (last) parameter is used so check if the date is the same as the start / end date since it could be the same but not in between
          const startTimeIsDuring = moment(data.start_time).isBetween(
            event.start_time,
            event.end_time,
            undefined,
            '[)'
          )
          const endTimeIsDuring = moment(data.end_time).isBetween(
            event.start_time,
            event.end_time,
            undefined,
            '(]'
          )

          const startTimeOverlaps = moment(event.start_time).isBetween(
            data.start_time,
            data.end_time,
            undefined,
            '[)'
          )
          const endTimeOverlaps = moment(event.end_time).isBetween(
            data.start_time,
            data.end_time,
            undefined,
            '(]'
          )

          if (
            startTimeOverlaps ||
            endTimeOverlaps ||
            startTimeIsDuring ||
            endTimeIsDuring
          ) {
            isTimeAvailable = false
            return false
          }
        } else if(viewBookingSlot.id === event.id) {
          if(viewBookingVideoSession) {
            event.video_session = 1
          } else {
            event.video_session = 0
          }
        }
      })

      if (!isTimeAvailable) {
        createInfo('error', `A session is already booked during this time`)
        return
      }

      viewBookingSlot.date = viewBookingDate
      setShowLoader(true)
      try {
        const response = await axios.post(
          `/sessions/update/${viewBookingSlot.id}`,
          data
        )
        if (response.data.error === false) {
          createInfo('success', `Session Updated`)
          setShowLoader(true)
          loadEvents(
            currentRange.from.format('YYYY-MM-DD'),
            currentRange.to.format('YYYY-MM-DD')
          )
          setShowLoader(false)
          resetModal()
        }
      } catch (error) {
        console.log(error)
      }
      setShowLoader(false)
    }

    return (
      <form onSubmit={updateBooking}>
        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="date"
              name="date"
              id="date"
              defaultValue={viewBookingDate}
              onChange={(e) => {
                setViewBookingDate(e.target.value)
              }}
              min={moment().format('YYYY-MM-DD')}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-4">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Client
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="client_name"
              id="client_name"
              disabled="disabled"
              defaultValue={viewBookingClient.name}
              onChange={(e) => {
                setViewBookingClient(e.target.value)
              }}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
            <p
              className="text-blue-500 text-sm cursor-pointer hover:text-blue-700 float-right"
              onClick={() => visitClient(viewBookingClient.id)}
            >
              View client
            </p>
          </div>
        </div>

        <div className="flex mb-3">
          <p className="mr-4">Video Session:</p>
          <Switch
            checked={viewBookingVideoSession}
            onChange={(e) => {setViewBookingVideoSession(e)}}
            className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
          >
            <span className="sr-only">Is this a video session</span>
            <span className="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
              <span
                aria-hidden="true"
                className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
              >
                <svg fill="none" viewBox="0 0 12 12" className="h-3 w-3 text-gray-400">
                  <path
                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span
                aria-hidden="true"
                className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
              >
                <svg fill="currentColor" viewBox="0 0 12 12" className="h-3 w-3 text-indigo-600">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </div>

        <div className={`mb-3 ${!startTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="start"
            className="block font-medium text-gray-600 text-sm"
          >
            Start
          </label>
          <select
            defaultValue={viewBookingSlot.start}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(e.target.value, viewBookingSlot.end)
              setStartTimeValid(valid)
              viewBookingSlot.start = e.target.value
            }}
          >
            <option disabled>Select Start Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!startTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        <div className={`mb-3 ${!endTimeValid ? 'select-error' : ''}`}>
          <label
            htmlFor="end"
            className="block font-medium text-gray-600 text-sm"
          >
            End
          </label>
          <select
            defaultValue={viewBookingSlot.end}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => {
              const valid = checkTime(viewBookingSlot.start, e.target.value)
              setEndTimeValid(valid)
              viewBookingSlot.end = e.target.value
            }}
          >
            <option disabled>Select End Time</option>
            {intervals('7:00', '23:45')?.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            })}
          </select>
          {!endTimeValid && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="error-icon"
            />
          )}
        </div>

        {admin && (
          <div className="w-full flex justify-end">
            <p
              className="text-red-500 text-sm cursor-pointer hover:text-red-700"
              onClick={() => cancelBookingClicked(viewBookingSlot)}
            >
              Cancel Session
            </p>
          </div>
        )}

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const ViewSessionElement = () => {
    return (
      <div>
        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              readOnly
              type="date"
              name="date"
              id="date"
              defaultValue={viewBookingDate}
              min={moment().format('YYYY-MM-DD')}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Client
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              readOnly
              type="text"
              name="client_name"
              id="client_name"
              disabled="disabled"
              defaultValue={viewBookingClient.name}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
            <p
              className="text-blue-500 text-sm cursor-pointer hover:text-blue-700 float-right"
              onClick={() => visitClient(viewBookingClient.id)}
            >
              View client
            </p>
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Start
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              readOnly
              type="text"
              name="start"
              id="start"
              defaultValue={viewBookingSlot.start}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            End
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              readOnly
              type="text"
              name="end"
              id="end"
              defaultValue={viewBookingSlot.end}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        {admin && (
          <div className="w-full flex justify-end">
            <p
              className="text-red-500 text-sm cursor-pointer hover:text-red-700"
              onClick={() => cancelBookingClicked(viewBookingSlot)}
            >
              Cancel Session
            </p>
          </div>
        )}

        <div className="flex mt-2 w-full justify-end">
          <button type="button" className="btn mt-4 w-1/2" onClick={resetModal}>
            Close
          </button>
        </div>
      </div>
    )
  }

  const DeleteAvailabilityElement = () => {
    const deleteAvailabilityFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        await axios.get('/availabilities/delete/' + deleteAvailability.id)
        createInfo('error', `Availability Deleted`)
        setShowLoader(true)
        loadEvents(
          currentRange.from.format('YYYY-MM-DD'),
          currentRange.to.format('YYYY-MM-DD')
        )
        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={deleteAvailabilityFn}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to delete this availability?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  useEffect(() => {
    getCancelReasons()
  }, [])

  const getCancelReasons = async (e) => {
    try {
      const response = await axios.get('/cancel-reasons/all')
      setCancelReasons(response.data.result)
    } catch (error) {
      console.log(error)
    }
    setShowLoader(false)
  }

  const handleChange = (e) => {
    setCancelSession({ ...cancelSession, reason_id: e.target.value })
  }

  const CancelSessionElement = () => {
    const cancelSessionFn = async (e) => {
      e.preventDefault()
      if (!cancelSession.reason_id) {
        createInfo('error', 'Please select a cancellation reason')
        return
      }
      setShowLoader(true)
      resetModal(false)
      try {
        await axios.get(
          '/sessions/delete/' + cancelSession.id + '/' + cancelSession.reason_id
        )
        createInfo('error', `Session Cancelled`)
        setShowLoader(true)
        loadEvents(
          currentRange.from.format('YYYY-MM-DD'),
          currentRange.to.format('YYYY-MM-DD')
        )
        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={cancelSessionFn}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to cancel this session?
          </p>
          <div>
            <label
              htmlFor="end"
              className="block font-medium text-gray-600 text-sm"
            >
              Please select a reason for cancelling this booking
            </label>
            <select
              value={cancelSession.reason_id ?? 0}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={handleChange}
            >
              <option value="0" disabled>
                Select Cancel Reason
              </option>
              {cancelReasons?.map((reason) => {
                return (
                  <option key={reason.id} value={reason.id}>
                    {reason.title}
                  </option>
                )
              })}
            </select>
          </div>
          {cancelSession.reason_id && cancelSession.reason_id !== 0 && (
            <div className="flex content-center mt-4">
              <span className="content-center text-red-600">
                {(JSON.parse(cancelSession.reason_id).id === 1 || JSON.parse(cancelSession.reason_id).id === 2)
                  ? 'Make sure you let the client know, they will not be able to get this session back.'
                  : 'This session will not be taken from the client’s allowance.'}
              </span>
            </div>
          )}
          <div className="flex mt-2 w-full">
            <button
              type="submit"
              className={`mt-4 mr-4 w-1/2 ${
                !cancelSession.reason_id || cancelSession.reason_id === '0'
                  ? 'cursor-not-allowed text-sm inline-flex justify-center items-center bg-gray-500 border-gray-500 text-white font-bold py-2.5 px-4 border rounded'
                  : 'btn blue'
              }`}
              disabled={
                !cancelSession.reason_id || cancelSession.reason_id === '0'
              }
            >
              Yes, cancel this session
            </button>

            <button
              type="button"
              className="btn red mt-4 w-1/2"
              onClick={resetModal}
            >
              No
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = () => {
    setAddAvailabilityFlag(false)
    setAvailabilityData({ services: [] })
    setViewBookingSlot({})
    setAvailabilityDate('')
    setViewBookingDate('')
    setStartTimeValid(true)
    setEndTimeValid(true)

    setEditAvailabilityFlag(false)
    setEditBookingFlag(false)
    setViewBookingFlag(false)

    setShowModal(false)
    setModalTitle('')
  }

  return (
    <div>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      {/* Nav */}
      <div className="mb-4 flex justify-between">
        <div className="flex">
          <button
            className="h-10 px-6 rounded-full border border-slate-300 bg-white text-sm font-medium flex items-center mr-4 hover:bg-slate-300"
            onClick={goToToday}
          >
            Today
          </button>

          <button
            className="h-10 w-10 rounded-full border border-slate-300 bg-white text-sm font-medium flex items-center justify-center mr-2 hover:bg-slate-300"
            onClick={prevWeek}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>

          <button
            className="h-10 w-10 rounded-full border border-slate-300 bg-white text-sm font-medium flex items-center justify-center mr-4 hover:bg-slate-300"
            onClick={nextWeek}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>

          <span className="text-lg h-10 font-medium flex items-center justify-center">
            {moment(currentRange.from).format('DD/MM/YYYY')} -{' '}
            {moment(currentRange.to).format('DD/MM/YYYY')}
          </span>
        </div>

        <div className="flex">
          {calendarType === 'bookings' && userId && (
            <div className="flex items-center">
              <input
                type="checkbox"
                id="cancelledCheckbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label className="ml-1" htmlFor="cancelledCheckbox">
                Show Cancellations
              </label>
            </div>
          )}
          {/* {calendarType === 'bookings' && (
            <button
              className="btn ml-3 green"
              onClick={() => addBooking(moment().format('YYYY-MM-DD'))}
            >
              <FontAwesomeIcon icon={faCalendarPlus} className="mr-2" />
              Book
            </button>
          )} */}
          {calendarType === 'availability' && userId && (
            <button
              className="btn ml-3 green"
              onClick={() => addAvailability(moment().format('YYYY-MM-DD'))}
            >
              <FontAwesomeIcon icon={faCalendarPlus} className="mr-2" />
              Add Availability
            </button>
          )}
          {!loaded && (
            <button className="btn ml-3" onClick={loadCalendar}>
              Load Calendar
            </button>
          )}
        </div>
      </div>
      {calendarType == 'bookings' && (
        <div className="flex items-end space-x-4 mb-4">
          <div className="font-semibold mt-2">Key:</div>
          <div className="flex items-center mt-2">
            <span className="block w-4 h-4 bg-[#2f64a7] rounded-full mr-2"></span>
            <span className="text-sm">Counselling</span>
          </div>
          <div className="flex items-center mt-2">
            <span className="block w-4 h-4 bg-[#eb2ae4] rounded-full mr-2"></span>
            <span className="text-sm">CYP Counselling</span>
          </div>
          <div className="flex items-center mt-2">
            <span className="block w-4 h-4 bg-[#fcab47] rounded-full mr-2"></span>
            <span className="text-sm">Coaching</span>
          </div>
          <div className="flex items-center mt-2">
            <span className="block w-4 h-4 bg-[#94a3b8] rounded-full mr-2"></span>
            <span className="text-sm">Pending</span>
          </div>
          <div className="flex items-center mt-2">
            <span className="block w-4 h-4 bg-red-400 rounded-full mr-2"></span>
            <span className="text-sm">Cancelled</span>
          </div>
        </div>
      )}
      <div className="w-full h-full rounded-xl shadow-md overflow-hidden relative">
        {!loaded && (
          <div
            onClick={loadCalendar}
            className="absolute cursor-pointer w-full h-full opacity-40 bg-white z-10 flex justify-center items-center"
          >
            <p className="text-2xl xl:text-4xl text-black">
              Click here or 'Load Calendar' to view
            </p>
          </div>
        )}
        <div
          className={`calendar-block ${
            calendarType === 'availability' ? 'hidden' : ''
          }`}
        >
          <Calendar
            ref={bookingCalendarRef}
            defaultView="month"
            taskView={false}
            disableDblClick={true}
            useCreationPopup={false}
            useDetailPopup={false}
            scheduleView={['time']}
            gridSelection={false}
            week={{
              startDayOfWeek: WEEK_DAY_START,
              hourStart: DAY_START_HOUR,
              hourEnd: DAY_END_HOUR,
              taskView: false,
              eventView: ['time'],
            }}
            template={{
              time: function (schedule) {
                return getTimeTemplate(schedule, false)
              },
              popupEdit: function () {
                return 'Edit'
              },
              popupDetailBody: function (schedule) {
                return getPopupDetailBody(schedule)
              },
            }}
          />
        </div>
        <div
          className={`calendar-block ${
            calendarType === 'bookings' ? 'hidden' : ''
          }`}
        >
          <Calendar
            style={{ height: '100%', width: '100%' }}
            ref={availabilityCalendarRef}
            defaultView="week"
            taskView={false}
            disableDblClick={true}
            useCreationPopup={false}
            useDetailPopup={false}
            scheduleView={['time']}
            gridSelection={false}
            week={{
              startDayOfWeek: WEEK_DAY_START,
              hourStart: DAY_START_HOUR,
              hourEnd: DAY_END_HOUR,
              taskView: false,
              eventView: ['time'],
            }}
            template={{
              time: function (schedule) {
                return getTimeTemplate(schedule, false)
              },
              popupEdit: function () {
                return 'More'
              },
              popupDetailBody: function (schedule) {
                return getPopupDetailBody(schedule)
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default TuiCalendar
